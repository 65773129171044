<template>
    <template v-if="props.primaryButtonSpec.enableUrl">
        <NuxtLink :id="props.primaryButtonSpec.buttonElName" class="gibs-primary-button"
            :class="props.primaryButtonSpec.class" :external="props.primaryButtonSpec.externalSource"
            :to="props.primaryButtonSpec.sourceUrl" :target="openLinkInNewTab()">
            <span>
                {{ props.primaryButtonSpec.buttonText }}
            </span>
        </NuxtLink>
    </template>
    <template v-else>
        <button :disabled="props.primaryButtonSpec.disabled" :id="props.primaryButtonSpec.buttonElName"
            :type="props.primaryButtonSpec.type"  class="gibs-primary-button-click-event" :class="{ 'bg-cultured': props.primaryButtonSpec.load, [props.primaryButtonSpec.class]: true }">
            <template v-if="!props.primaryButtonSpec.load">
                <span>
                    {{ props.primaryButtonSpec.buttonText }}
                </span>
            </template>
            <template v-else>
                <span :class="props.loaderClass">
                    <span class="loader"></span>
                </span>
            </template>
        </button>
    </template>
</template>
  
<script lang="ts">
var buttonObject: IControlButton = {
    buttonElName: "primary-button",
    buttonText: "Find out more",
    class: "",
    enableUrl: true,
    externalSource: false,
    openNewTab: false,
    sourceUrl: "/",
    load: false,
    disabled: false
};
</script>

<script setup lang="ts">

const props = defineProps({
    primaryButtonSpec: {
        type: Object,
        default: buttonObject
    },
    loaderClass: {
        type: String,
        default: 'button-spinner-container'
    }
});

function openLinkInNewTab(): string {
    return props.primaryButtonSpec.openNewTab ? '_blank' : '';
}
</script>
